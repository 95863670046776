<template>
    <div v-if="!loader">
        <b-card class="row-height" no-body>
            <div>
                <b-card no-body class="card-statistics" style="">
                    <b-card-body class="statistics-body">
                        <h2 class="text-purple font-weight-bold mb-1">
                            {{ $t("partner_details") }}
                        </h2>
                        <br><br>

                        <div>
                            <b-form>
                                <b-row>
                                    <b-col md="4">
                                        <b-form-group :label="$t('start_date')">
                                            <b-form-datepicker v-model="start_date"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                        <b-form-group :label="$t('end_date')">
                                            <b-form-datepicker v-model="end_date"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="2">
                                        <b-form-group :label="$t('username')" class="pt-1">
                                            <b-form-checkbox
                                                v-model.trim="show_username"
                                                required
                                                true-value="true"
                                                false-value="false"
                                                @change="showUsername()"
                                            >
                            <span>
                              {{ $t("show_username") }}
                            </span>
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="1">
                                        <b-form-group label="" class="mt-2 pt-1">
                                            <b-button @click="getData()" variant="primary" size="sm">{{$t('search')}}
                                            </b-button>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="1">
                                        <b-form-group label="" class="mt-2 pt-1">
                                            <b-button class="d-flex" @click="downloadPdf()" variant="primary" size="sm">
                                                <FeatherIcon size="12" icon="DownloadIcon"></FeatherIcon>
                                                PDF
                                            </b-button>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                            <b-table hover :items="reportData" :fields="visibleFields" responsive>
                                <template #head()="scope">
                                    <div class="text-nowrap">{{ $t(scope.label) }}</div>
                                </template>
                                <template #cell(invoice_id)="data">
                                    <span v-if="data.item.invoice_id">I-{{data.item.invoice_id}}</span>
                                    <span v-else>-</span>
                                </template>
                                <template #cell(company_id)="data">
                                    <span v-if="data.item.company_id">C-{{data.item.company_id}}</span>
                                    <span v-else>-</span>
                                </template>
                                <template #cell(currency_id)="data">
                                    <span v-if="data.item.currency">{{data.item.currency.name}}</span>
                                    <span v-else>-</span>
                                </template>
                                <template #cell(amount)="data">
                                    <span v-if="data.item.amount">{{amountFormat(data.item.amount, data.item.currency.code)}}</span>
                                    <span v-else>-</span>
                                </template>
                                <!--<template #cell(username)="data">-->
                                    <!--<span v-if="data.item.user">{{data.item.user[0].name}} {{data.item.user[0].last_name}}</span>-->
                                <!--</template>-->
                                <!--<template #cell(discount)="data">-->
                                    <!--<span>{{parseInt(data.item.referral_partner.discount)}} % / {{(240*(data.item.referral_partner.discount/100)) | formatAmount}} CHF</span>-->
                                <!--</template>-->
                                <!--<template #cell(commission)="data">-->
                                    <!--<span>{{parseInt(data.item.referral_partner.percentage)}} % / {{(240*(data.item.referral_partner.percentage/100)) | formatAmount}} CHF</span>-->
                                <!--</template>-->
                                <!--<template #cell(status)="data">-->
                                    <!--<span v-if="data.item.sold_invoices && data.item.sold_invoices.length > 0">-->
                                        <!--{{$t('completed')}}-->
                                    <!--</span>-->
                                    <!--<span v-else>{{$t('pending')}}</span>-->
                                <!--</template>-->
                            </b-table>
                            <div class="col-12 row alert alert-secondary p-1">
                                <strong>{{ $t("total") }}: </strong>
                                <p class="ml-auto" v-if="total"> 0">{{ total | formatAmount }}</p>
                                <p class="ml-auto" v-else> 0.00 CHF</p>
                            </div>
                        </div>
                        <div>
                        </div>
                    </b-card-body>
                </b-card>
            </div>
        </b-card>
    </div>
    <div v-else><img src="/new-loader.svg"/></div>
</template>
<script>
  import {ValidationProvider, ValidationObserver,} from "vee-validate";
  import Table from "@/components/Common/Table2";
  import fileDownload from "js-file-download";
  import {CurrencyAmountFormat} from '@core/utils/CurrencyAmountFormat';

  import i18n from "@/libs/i18n";
  import {forEach} from "postcss-rtl/lib/affected-props";

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      Table
    },
    data() {
      return {
        show_username: true,
        total: 0,
        company: null,
        billing: null,
        start_date: null,
        end_date: null,
        loader: false,
        preregistered: false,
        reportFields: [
          {
            label: "invoice_id",
            align: "start",
            key: "invoice_id",
            visible: true
          },
          // {
          //   label: "username",
          //   key: "username",
          //   sortable: false,
          //   visible: true
          // },
          {
            label: "company_id",
            align: "start",
            key: "company_id",
            sortable: false,
            visible: true
          },
          {
            label: "currency_id",
            align: "start",
            key: "currency_id",
            sortable: false,
            visible: true
          },
          {
            label: "amount",
            align: "start",
            key: "amount",
            sortable: false,
            visible: true
          },

        ],
        reportData: null,
      };
    },
    computed: {
      visibleFields() {
        return this.reportFields.filter(field => field.visible)
      }
    },
    created() {
      this.start_date = new Date();
      this.end_date = new Date(this.start_date);
      this.end_date.setDate(this.start_date.getDate() + 1);
      this.start_date.getDate();
      this.getData();
    },
    methods: {
      searchReport() {
      },

      amountFormat(amount, currency) {
        return CurrencyAmountFormat(amount, currency)
      },

      showUsername() {
        if (this.show_username) {
          this.reportFields[1].visible = true;
        } else {
          this.reportFields[1].visible = false;
        }
      },
      downloadPdf(item) {
        this.$http
            .post(
                `/partners/${this.$route.params.id}/pdf`, {
                  start_date: this.start_date,
                  end_date: this.end_date,
                  show_username: this.show_username
                },
                {
                  responseType: "blob",
                }
            )
            .then((response) => {
              fileDownload(response.data, 'Report.pdf');
            })
            .catch((error) => {
            });
      },
      getData() {
        this.total = 0;
        this.$http
            .post(`/partners/${this.$route.params.id}`, {
              start_date: this.start_date,
              end_date: this.end_date,
              show_username: this.show_username
            })
            .then((res) => {
              if (res) {
                this.reportData = res.data.value;
                this.calculateTotal();
              }
            });
      },
      calculateTotal() {
        this.reportData.forEach(report => {
          if (report.sold_invoices.length > 0) {
            this.total += (240 * (report.referral_partner.percentage / 100));
          }
        });
      },
      showSuccessMessage(message, type) {
        let text = this.$t(message);
        this.$swal({
          position: "center",
          icon: "success",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      },
    },
  };
</script>
